import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import HomePage from './pages/HomePage';
import ProjectPage from './pages/ProjectPage';
import ActivitiesPage from './pages/ActivitiesPage';
import DownloadPage from './pages/DownloadPage';
import ContactPage from './pages/ContactPage';

function App() {
  return (
    <Container className="App d-flex flex-column min-vh-100">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/project" element={<ProjectPage />} />
          <Route path="/activities" element={<ActivitiesPage />} />
          <Route path="/download" element={<DownloadPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </Container>
  );
}

export default App;
