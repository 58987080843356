import Header from "../components/Header";
import Main from "../components/Main";
import Footer from "../components/Footer";

import projectLogo from "../download/map2health_project_logo.png";
import projectTimeshTemplate from "../download/IPA_Monitoring_Timesheets.zip";

export default function DownloadPage() {
    return (
        <>
            <Header />
            <Main>
                <div className="Article BorderTop">
                    <h2>Download</h2>
                    <p><a href={projectLogo} download="map2health_project_logo.png">Project logo</a></p>
                    <p>
                        <a href={projectTimeshTemplate} download="IPA_Monitoring_Timesheets.zip">Project timesheets</a>
                    </p>
                </div>
            </Main>
            <Footer />
        </>
    );
}
