import { Container } from "react-bootstrap";
import './css/Main.css';

export default function Main({ children }) {
    return (
        <main className="Main">
            <Container>
                {children}
            </Container>
        </main>
    );
}