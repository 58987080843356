import Header from '../components/Header';
import Main from '../components/Main';
import Footer from '../components/Footer';

export default function ProjectPage() {
    return (
        <>
            <Header />
            <Main >
                <div className="Article LimitLineLen">
                    <h2>Project approach</h2>
                    <p>
                        National food-based dietary guidelines (NFBDGs) provide context-specific advice and principles on healthy diets and lifestyles, which are rooted on sound evidence, and respond to a country's public health and nutrition priorities, food production and consumption patterns, sociocultural influences, food composition data, and accessibility, among other factors. So far Montenegro has just identified the importance of these initiatives, but without proposing suitable solutions for resolving this issue and ensuring all recommended activities towards a healthier nation. Therefore, <strong>MAP2HEALTH</strong> ensures that Montenegro stays in a loop of current developments in the area, respecting national health and boosting research and innovation capacities of various national stakeholders in addition to improving the health of the general population. In every developed country the developing NFBDGs is an important part of nutrition policy. Countries are encouraged to integrate these with other health promotion policies such as those for physical activity, smoking cessation, or the reduction of alcohol-related harm. Since country-specific nutrient intake levels, availability of food products, and cultural characteristics affect NFBDG development, NFBDGs are usually unique to the population or country that developed them.
                    </p>
                    <p>
                        Since Montenegro is on its road towards the EU it should be in line with EU initiatives in this area. <strong>MAP2HEALTH</strong> aims towards achieving this important goal. This project is aligned with the recent FAO initiative for establishing NFSBDG in 5 countries (Montenegro, Serbia, Kyrgyzstan, Uzbekistan, and Azerbaijan). Through the described activities, cooperation with EFSA will be further strengthened, and the data obtained from National Dietary Survey conducted in Montenegro will be further valorised.
                    </p>
                    <p>
                        Having in mind numerous activities envisaged by the project, which will to the highest extent engage the scientific, technical and expert potential in Institute of Public Health, Food Hub and MG SOFT, improve their knowledge and skills in using innovative IT solutions in the interests of human health, those key pillars in the project were identified:
                    </p>
                </div>

                <div className="Tablewrap">
                    <table className="SimpleTable LimitLineLen">
                        <thead>
                            <tr>
                                <th>Timeframe of the activities under MAP2HEALTH</th>
                                <th>Month <br /> 1-3</th>
                                <th>Month <br /> 3-6</th>
                                <th>Month <br /> 6-9</th>
                                <th>Month <br /> 9-12</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Establishing the <strong>MAP2HEALTH</strong> consortium and roadmap of the activities</td>
                                <td>x</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Assessment of the situation and the agreement of all relevant stakeholders</td>
                                <td>x</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Necessary trainings and pilot testing of the methodology</td>
                                <td>x</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Data collection and modelling of the pilot software. Filed work</td>
                                <td></td>
                                <td>x</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Trial testing and validation</td>
                                <td></td>
                                <td></td>
                                <td>x</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Connection with international databases</td>
                                <td></td>
                                <td></td>
                                <td>x</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Announcing the launch of the software</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>x</td>
                            </tr>
                            <tr>
                                <td>Planning future financing of the initiative. Publishing of the results. Dissemination and
                                    sustainability</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>x</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="Article LimitLineLen">
                    <h2>Partners capacity</h2>
                    <p>
                        <strong>Institute of Public Health of Montenegro (IPHMNE)</strong> is licensed scientific-research institution. Institute conducts researches into a better understanding of health and the prevention of ill health, with focus on a wide range of public health concerns, including epidemiological studies, health promotion studies, Social Medicine studies.
                    </p>
                    <p>
                        Institute of Public Health is the teaching base of the Medical Faculty in Podgorica, University of Montenegro. Institute of Public Health as a preventive-medical-health organization at tertiary level of health care system, among other tasks, deals with healthy life styles, quality of nutrition and nutrition habits, analyzing of health quality of foods, consumer goods and drinking water. IPH monitors, analyses and evaluates influence of environment (air, soil and noise) on population health. IPH covers: monitoring, researching and studying of population health and health culture, causes, spreading and prevention of infectious diseases, factors of risk of chronic non-infectious diseases and other diseases of high social-medical significance as well as organization, working and development of health service and proposing and carrying out appropriate measures for prevention and improvement of population health.
                    </p>
                    <p>
                        There are 8 centers in the Institute of Public Health: Centre for Science and Education, Centre for Health Promotion, Centre for Hygiene and Health Ecology, Centre for Control and Prevention of Communicable Diseases, Centre for Control and Prevention of Chronic Diseases, Centre for Health Policy and Management, Centre for Data Evidences and Public Health Reseearches and Centre for Medical Microbiology.
                    </p>
                    <p>
                        Institute holds laboratories with adequate staff and equipment for performing all types of examination providing by domestic regulations in the field of environment protection, quality control of food, water and consumer goods.
                    </p>
                    <p className="ListTitle">Equipment:</p>
                    <ul>
                        <li>
                            Computer room — 20 working units
                        </li>
                        <li>
                            Amphitheatre — accommodates 220 people
                        </li>
                        <li>
                            Meeting room — accommodates 30 people
                        </li>
                        <li>
                            Each office is equipped with PCs
                        </li>
                        <li>
                            Copy machines, fax machines
                        </li>
                    </ul>
                    <p>
                        <strong>Company for information technology engineering MGSoft</strong> was founded in 1996, and member of Oracle Partner Network since 1998. MGSoft is one of Montenegro's leading companies in the area of project development, design, implementation and maintenance of information systems. MGSoft employs highly educated expert team, engaged in all phases of development and implementation process of application and system solutions. Their team is focused on the development of most comprehensive application solutions by using most advanced Oracle technologies — Oracle databases, Oracle development tools and Open Source solutions where applicable.
                        <br />
                        In 2012 the company was certified for: (i) ISO 9001:2008 Quality Management System, for Design, implementation, and maintenance of IT solutions EA33, and (ii) ISO 27001:2005 Information Security Management System, for Design, development, Implementation, Maintenance and Provisioning of IT services to external and internal customers.
                    </p>
                    <p>
                        MGSoft is also part of EUREKA network and participated in EUREKA network R&amp;D through project titled E!5000 ELDORO (Electronic Doctor's Round) in cooperation with companies Software602 — Czech Republic; Alpineon d.o.o. — Ljubljana, Slovenia; Sestek a.s. — Istanbul, Turkey; Fakultni nemocnice Motol — Prague, Czech Republic; Edosoft Factory, Las Palmas de Gran Canaria and InnoPNT Co., Ltd&nbsp;—&nbsp;Seoul, South Korea.
                    </p>
                    <p className="ListTitle">
                        There is a whole set of tailor made software, of which we emphasize the following:
                    </p>
                    <ul>
                        <li>
                            Management of Board of Excellence, Republic Health Insurance Fund of Montenegro;
                        </li>
                        <li>
                            Control of Contribution Payment Mechanisms, Republic Health Insurance Fund of Montenegro;
                        </li>
                        <li>
                            Control of Distribution and Utilization of Pharmaceuticals, Republic Health Insurance Fund of Montenegro;
                        </li>
                        <li>
                            Management of pharmacies, Montefarm, Podgorica;
                        </li>
                        <li>
                            Realization of Insured Persons Rights on Basis of Compulsory Health Insurance;
                        </li>
                        <li>
                            IS for Primary Healthcare Centers PHC in Montenegro(19 PHC);
                        </li>
                        <li>
                            IS for General Hospitals in Montenegro (7 General Hospitals);
                        </li>
                        <li>
                            IS for Agency for Pharmaceuticals and Medical Devices;
                        </li>
                        <li>
                            IS form Pension and Disability Insurance Fund PDIF;
                        </li>
                        <li>
                            Health Statistics Information System (HSIS) for Institute of Public Health of Montenegro(financed by World Bank), etc.
                        </li>
                    </ul>
                    <p>
                        <strong>Centre of Excellence for digitalization of microbial food safety risk assessment and quality parameters for accurate food authenticity certification (FoodHub)</strong>, founded in January 2020 at UDG has the overall goal to offer reliable, science-based solutions for food safety risk elimination and hazard identification, digitalized food safety risk assessment tools, reliable certification and tracing of food authenticity, promotion and ready to use applications for the food production industry and tourism sector.
                    </p>
                    <p>
                        Its specific objectives are to profoundly impact scientific development of Montenegro, integrate science, food production, food control and public health through modern, efficient, sustainable, and knowledge-based data processing. The proposed <strong>MAP2HEALTH</strong> action is tailored specifically to ensure high quality transfer of knowledge and innovations as an integral part of the FoodHub goals.
                    </p>
                </div>
            </Main>
            <Footer />
        </>
    );
}
