import Header from "../components/Header";
import Main from "../components/Main";
import Footer from "../components/Footer";

export default function ActivitiesPage() {
    return (
        <>
            <Header />
            <Main>
                <div className="Tablewrap">
                    <ReportTable />
                </div>
            </Main>
            <Footer />
        </>
    );
}

function ReportTable() {
    return (
        <table className="ReportTable">
            <ReportTableHeader />
            <ReportTableBody />
        </table>
    );
}

function ReportTableHeader() {
    return (
        <thead>
            <tr>
                <th colSpan="14" className="TableLabel">Year 1</th>
            </tr>
            <tr>
                <td></td>
                <th colSpan="6" className="Nobr">Half-year 1 (months 1-6)</th>
                <th colSpan="6" className="Nobr">Half-year 2 (months 7-12)</th>
                <td></td>
            </tr>
            <tr>
                <th>Activity</th>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
                <th>7</th>
                <th>8</th>
                <th>9</th>
                <th>10</th>
                <th>11</th>
                <th>12</th>
                <th>Implementing body</th>
            </tr>
        </thead>
    );
}

function ReportTableBody() {
    return (
        <tbody>
            <TableRow
                activity={
                    <>
                        <strong>T.1.1</strong><br />
                        Establishment of <strong>MAP2HEALTH</strong> structure and management
                    </>
                }
                implBody={<>IPHMNE</>}
                marked={markThese([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])}
            />

            <TableRow
                activity={
                    <>
                        <strong>T.1.2</strong><br />
                        Implementation of Administrative, Financial Management and Quality Assurance procedures for <strong>MAP2HEALTH</strong>
                    </>
                }
                implBody={<>IPHMNE</>}
                marked={markThese([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])}
            />

            <TableRow
                activity={
                    <>
                        <strong>T.1.3</strong><br />
                        Data management plan and innovation management
                    </>
                }
                implBody={<>MGSoft</>}
                marked={markThese([8, 9, 10])}
            />

            <TableRow
                activity={
                    <>
                        <strong>T.2.1</strong><br />
                        Assessment of the situation and the agreement of all relevant stakeholders
                    </>
                }
                implBody={<>IPHMNE</>}
                marked={markThese([3, 4, 5, 6])}
            />

            <TableRow
                activity={
                    <>
                        <strong>T.2.2</strong><br />
                        Creation and implementation of a survey on children's dietary habits (1-9 years)
                    </>
                }
                implBody={<>CoE&nbsp;—&nbsp;FoodHub</>}
                marked={markThese([4, 5, 6, 7, 8, 9])}
            />

            <TableRow
                activity={
                    <>
                        <strong>T.2.3</strong><br />
                        Mobility of staff between partner institutions
                    </>
                }
                implBody={<>IPHMNE</>}
                marked={markThese([2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])}
            />

            <TableRow
                activity={
                    <>
                        <strong>T.2.4</strong><br />
                        Organization of workshop for the stakeholders
                    </>
                }
                implBody={<>CoE&nbsp;—&nbsp;FoodHub</>}
                marked={markThese([10])}
            />

            <TableRow
                activity={
                    <>
                        <strong>T.3.1</strong><br />
                        Analysis, design and implementation of the system for data collection, processing, storage and analysis
                    </>
                }
                implBody={<>MGSoft</>}
                marked={markThese([3, 4, 5, 6, 7, 8])}
            />

            <TableRow
                activity={
                    <>
                        <strong>T.3.2</strong><br />
                        Data analysis and modelling of the pilot software
                    </>
                }
                implBody={<>MGSoft</>}
                marked={markThese([3, 4, 5, 6, 7, 8])}
            />

            <TableRow
                activity={
                    <>
                        <strong>T.3.3</strong><br />
                        Trial testing and validation.<br />
                        Announcing the launch of the software
                    </>
                }
                implBody={<>MGSoft</>}
                marked={markThese([8, 9, 10])}
            />

            <TableRow
                activity={
                    <>
                        <strong>T.4.1</strong><br />
                        Dissemination, communication and sustainability strategy (DCS)
                    </>
                }
                implBody={<>IPHMNE</>}
                marked={markThese([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])}
            />

            <TableRow
                activity={
                    <>
                        <strong>T.4.2</strong><br />
                        Creating a Promotional Kit
                    </>
                }
                implBody={<>CoE&nbsp;—&nbsp;FoodHub</>}
                marked={markThese([2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])}
            />

            <TableRow
                activity={
                    <>
                        <strong>T.4.3</strong><br />
                        Preparation for the final conference
                    </>
                }
                implBody={<>CoE&nbsp;—&nbsp;FoodHub</>}
                marked={markThese([12])}
            />
        </tbody>
    );
}

function TableRow({ activity, implBody, marked }) {
    const markedList = [...marked.entries()];
    return (
        <tr>
            <td>{activity}</td>
            {
                markedList.map(([index, field]) => {
                    return (
                        <Cell key={index} marked={field} />
                    );
                })
            }
            <td>
                <strong>{implBody}</strong>
            </td>
        </tr>
    );
}

function markThese(indices) {
    const result = new Array(12);
    result.fill(false);
    for (const i of indices) {
        result[i - 1] = true;
    }
    return result;
}

function Cell({ marked }) {
    return (
        <>
            {marked ? <td className="MarkedCell" /> : <td />}
        </>
    );
}
