import { Row, Col, Container } from "react-bootstrap";
import './css/Footer.css';

export default function Footer() {
    return (
        <footer className="Footer mt-auto">
            <Container>
                <Row className="FooterCols">
                    <Col className="Col ColDesc" xs={12} md={4}>
                        <h2>Map2Health</h2>
                        <p>
                            <a href="https://www.ijzcg.me">ijzcg.me</a>
                            <br />
                            <a href="https://www.mgsoft.me">mgsoft.me</a>
                            <br />
                            <a href="https://foodhub.udg.edu.me">foodhub.udg.edu.me</a>
                        </p>
                    </Col>
                    <Col className="Col ColNav" xs={12} md={4}>
                    </Col>
                    <Col className="Col ColContact" xs={12} md={4}>
                        <h2>Contact</h2>
                        <p>Project manager: Enisa Kujundžić, MD,
                            <br />
                            Specialist of Hygiene
                        </p>
                        <address>
                            <p><a href="mailto:enisa.kujundzic@ijzcg.me">enisa.kujundzic@ijzcg.me</a></p>
                            <p><a href="tel:+38220412888">+382 20 412 888</a></p>
                            <p><a href="tel:+38267302515">+382 67 302 515</a></p>
                            <br />
                            <p>Džona Džeksona bb<br />
                                81000 Podgorica<br />
                                Montenegro
                            </p>
                        </address>
                    </Col>
                </Row>
                <Row><Col className="FooterYear" xs={12}>2024</Col></Row>
            </Container>
        </footer>
    );
}
