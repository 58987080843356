import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import './css/Header.css';
import logo from '../images/derived/MAP2HEALTH_FULL_LOGO.png';

export default function Header({ dummyText }) {
    const menuItems = [
        {
            name: 'Home',
            link: '/home'
        },
        {
            name: 'Project',
            link: '/project'
        },
        {
            name: 'Activities',
            link: '/activities'
        },
        {
            name: 'Download',
            link: '/download'
        },
        {
            name: 'Contact',
            link: '/contact'
        },
    ];

    //console.log(dummyText);

    return (
        <header className="Header">
            <Container>
                <Row className="LogoAndNav">
                    <Col className="Logo" xs={12} md={4}><h1>Map2Health</h1></Col>
                    <Col className="MenuNav" xs={12} md={8}>
                        <Navbar className='justify-content-end'>
                            <Row className='justify-content-end'>
                                {
                                    menuItems.map(item => {
                                        return (
                                            <Col className="NavItem" key={item.name} xs={"auto"} sm={true} as={Nav.Item}>
                                                <Nav.Link className="NavItemLink" as={NavLink} to={item.link} end>{item.name}</Nav.Link>
                                            </Col>);
                                    })
                                }
                            </Row>
                        </Navbar>
                    </Col>
                </Row>
                <Row className='BorderTop justify-content-center HeaderImg'>
                    <Col xs={"auto"}>
                        <img src={logo} alt="header_bg" />
                    </Col>
                </Row>
            </Container>
        </header>
    );
}
