import Header from '../components/Header';
import Main from '../components/Main';
import Footer from '../components/Footer';

export default function HomePage() {
    return (
        <>
            <Header />
            <Main>
                <div className="Article LimitLineLen">
                    <p>
                        In the framework of the sustainable development of Montenegro, health is seen as an important factor in human capital, so there is a need to improve this area to the greatest extent possible. In addition, following the Smart Specialization Strategy of the EU, Smart Specialization Strategy of Montenegro, and the EU CAP strategic development, proposed <strong>MAP2HEALTH</strong> action will focus primarily on application of new analytical and digital components in the field of balanced and healthy nutrition of the Montenegrin population.
                    </p>
                    <p>
                        MG SOFT and CoE FoodHub are respectable partners within Institute of Public Health of Montenegro, who will undoubtedly contribute to the realisation of all identified challenges, while being in accordance with specificities and objectives of the call.
                    </p>
                </div>
                <div className="Article LimitLineLen">
                    <section>
                        <h2>Project goals</h2>
                        <ul>
                            <li>
                                <p>
                                    To improve the link between food and health science and the public, through the cooperation with the food industry, consumers and health practitioners, aiming towards the ultimate national goal of healthy nutrition and protecting human health.
                                </p>
                            </li>
                            <li>
                                <p>
                                    To create a sound cooperation platform between research institutions and companies, the action involved a component of sound integration of a pool of young researchers, in line with the S3.me priorities.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>MAP2HEALTH</strong> will enable every citizen in Montenegro to plan personalised healthy nutrition, based on scientific knowledge and recommendations.
                                </p>
                            </li>
                            <li>
                                <p>
                                    To enable the creation of the National Food System Based Dietary Guidelines (NFSBDG).
                                </p>
                            </li>
                            <li>
                                <p>
                                    To develop evidence base data necessary for the elaboration of NFSBDG that will enable extensive evaluation of dietary intake on individual and/or population group levels, dietary plan.
                                </p>
                            </li>
                        </ul>
                    </section>
                    <section>
                        <h2>Project outcome</h2>

                        <p>
                            The main project outcomes are National Food System Based Dietary Guidelines (NFSBDG) and developed Digital tool for creating balanced nutrition for health practitioners, food industry and consumers in Montenegro.
                        </p>
                        <p>
                            In order to achieve defined goals and objectives, the activities covered by this project will include activities related to implementation but also activities related to continuous support, in terms of project management and dissemination of project results to target groups and final beneficiaries.
                        </p>
                    </section>
                </div>
            </Main>
            <Footer />
        </>
    );
}
