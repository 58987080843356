import Header from '../components/Header';
import Main from '../components/Main';
import Footer from '../components/Footer';
import ijzcgLogo from '../images/ijzcg-logo-new.375471da.png';
import udgLogo from '../images/derived/udg_logo.jpg';
import mgLogo from '../images/derived/logoSVGMIN.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function ContactPage() {
    // const mgSoftLogo = process.env.PUBLIC_URL + '/logo.svg'; // had to import this SVG like this, hacky

    return (
        <>
            <Header />
            <Main>
                <div className="Article BorderTop">
                    <h2>Contact</h2>

                    <Row className="justify-content-around mt-4">
                        <Col xs={12} xl={3}>
                            <img alt="IJZ Logo" src={ijzcgLogo} className="LogoImg" />

                            <strong>
                                Project coordinator:<br />
                                Institute of Public Health of Montenegro
                            </strong>

                            <p>
                                Enisa Kujundžić, MD
                            </p>
                            <a href="mailto:enisa.kujundzic@ijzcg.me">enisa.kujundzic@ijzcg.me</a>
                            <p>
                                Džona Džeksona bb <br />
                                81000 Podgorica <br />
                                Phone: +382 20 412 888 <br />
                                Web: <a href="https://www.ijzcg.me">www.ijzcg.me</a> <br />
                            </p>

                            <br />

                        </Col>
                        <Col xs={12} xl={3}>
                            <img alt="MG Soft" src={mgLogo} className="LogoImg" />

                            <strong>
                                Project partner:<br />
                                MG Soft d.o.o. Podgorica
                            </strong>

                            <p>
                                Jelena Simićević
                            </p>
                            <a href="mailto:jelena.simicevic@mg-soft.co.me">jelena.simicevic@mg-soft.co.me</a>
                            <p>
                                Mitra Bakića bb<br />
                                81000 Podgorica<br />
                                Phone: +382 20 624 930<br />
                                Web: <a href="http://www.mgsoft.me">www.mgsoft.me</a><br />
                                E-mail: <a href="mailto:mg-soft@t-com.me">mg-soft@t-com.me</a>
                            </p>

                            <br />
                        </Col>
                        <Col xs={12} xl={3}>
                            <img alt="UDG" src={udgLogo} className="LogoImg" />

                            <strong>
                                Project partner:<br />
                                Centre of Excellence (FoodHub),<br />
                                University of Donja Gorica, Podgorica
                            </strong>

                            <p>
                                Prof. Aleksandra Martinović — FoodHub Director
                            </p>
                            <a href="mailto:aleksandra.martinovic@udg.edu.me">aleksandra.martinovic<wbr />@udg.edu.me</a>
                            <p>
                                Oktoih 1<br />
                                81000 Podgorica<br />
                                Phone: +382 69 737 403<br />
                                Web: <a href="https://foodhub.udg.edu.me">foodhub.udg.edu.me</a>
                            </p>
                        </Col>
                    </Row>

                </div>
            </Main>
            <Footer />
        </>
    );
}
